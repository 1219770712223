const LoginHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
    "Access-Control-Max-Age": "3600",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "Content-Type, authorization, set_cookie",
    "Accept": "application/json",
    "authorization": "some token",
    "Content-Type": "application/json"
};

export default LoginHeaders;
