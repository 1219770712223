import React from "react";

import "../base/list.css";
import axios from "axios";
import $ from "jquery";
import LogoutButton from "../login/Logout";
import inMemoryJWTService from "../base/InMemoryJWTService";
import {Link} from "react-router-dom";
import Footer from "../base/Footer";

class ChittiList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chits: [], loading: false, updateMessage: "", showError: false
        };
    }

    componentDidMount() {
        this.loadResults();
        console.log(this.state.loading);
        this.updateGraphics();
    }

    chitDetails = (ele) => {

        //the <tr> variable is use to set the parentNode from "ele
        let tr = ele.target.parentNode.parentNode.parentNode;
        console.log("tr", tr)
        //I get the value from the cells (td) using the parentNode (var tr)
        let editID = tr.cells[0].textContent;
        let editChittiName = tr.cells[1].textContent;
        let editChittiOwner = tr.cells[2].textContent;
        let editChittiCode = tr.cells[3].textContent;
        let editStatus = tr.cells[4].textContent;
        let editStartDate = new Date(tr.cells[5].textContent).toISOString().split('T')[0];
        let editEndDate = new Date(tr.cells[6].textContent).toISOString().split('T')[0];
        let editTotalMonths = tr.cells[7].textContent;
        let editTotalMembers = tr.cells[8].textContent;
        let editInterest = tr.cells[9].textContent;
        let editAdminCommission = tr.cells[10].textContent;
        let editChittiAmount = tr.cells[11].textContent;
        let editMonthlyPremium = tr.cells[12].textContent;

        //Prefill the fields with the gathered information
        let myModal = $('#editChittiModal');

        $('h4.modal-title', myModal).html('Edit Chitti Data: ' + editChittiName);
        $('#editChittiName', myModal).val(editChittiName);
        $('#editID', myModal).val(editID);
        $('#editChittiOwner', myModal).val(editChittiOwner);
        $('#editChittiCode', myModal).val(editChittiCode);
        $('#editStatus', myModal).val(editStatus);
        $('#editStartDate', myModal).val(editStartDate);
        $('#editEndDate', myModal).val(editEndDate);
        $('#editTotalMonths', myModal).val(editTotalMonths);
        $('#editTotalMembers', myModal).val(editTotalMembers);
        $('#editInterest', myModal).val(editInterest);
        $('#editAdminCommission', myModal).val(editAdminCommission);
        $('#editChittiAmount', myModal).val(editChittiAmount);
        $('#editMonthlyPremium', myModal).val(editMonthlyPremium);
    }

    filterResults = () => {
        var input = document.getElementById('myInput');
        var filter = input.value.toUpperCase();

        console.log(filter)

        $("tbody tr").each(function () {
            const chittiName = $(this).find("td:eq(1)").text();
            const status = $(this).find("td:eq(4)").text();
            console.log(chittiName)
            // Loop through all list items, and hide those who don't match the search query
            if ((chittiName.toUpperCase().indexOf(filter) > -1) || (status.toUpperCase().indexOf(filter) > -1)) {
                $(this).css("display", "");
            } else {
                $(this).css("display", "none");
            }
        });
    }

    captureError = (msg) => {
        this.setState({ updateMessage: msg, showError: true }, () => {
            setTimeout(() => {
                this.setState({ showError: false });
            }, 5000);
        });
    };

    render() {
        return (
                <div className="table-responsive fixed">
                    <LogoutButton/>

                    <div className="App">
                        <div className="container-xxl">
                            <div className="table-wrapper">
                                <div className="table-title">
                                    <div className="row">

                                        <div className="col-md-16">
                                            <h2>Manage <b>GIRIFY</b> Chits </h2>
                                        </div>

                                        <div className="col-sm-16">
                                            <Link to="/createChitti" className="btn btn-success"><i
                                                className="material-icons">&#xE147;</i><span>Add New Chit Details</span></Link>
                                        </div>

                                    </div>
                                </div>
                                <div className="row table-responsive">
                                    <input type="text" id="myInput" onKeyUp={this.filterResults}
                                           placeholder="Search for names or status.."/>
                                </div>
                                <div id="noResultsDiv">
                                    {this.state.chits.length == 0 && 'No Chitti Assigned to your account'}
                                    {this.state.showError && <div>{this.state.updateMessage}</div>}
                                </div>
                                <table className="table table-responsive table-striped table-hover" id={"chittiTable"}>
                                    <thead className="thead-dark">
                                    <tr id="trHeaders">
                                        <th>ChitID</th>
                                        <th>Chitti Name</th>
                                        <th>Chitti Owner</th>
                                        <th>Chitti Code</th>
                                        <th>Chitti Status</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>No of Months</th>
                                        <th>Total Members</th>
                                        <th>Rate of Interest</th>
                                        <th>Admin Commission</th>
                                        <th>Chitti Amount</th>
                                        <th>Monthly Premium</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody id={"tableBody"}>

                                    {this.state.loading && this.state.chits.map(item => (<tr id={"tableRow"}>
                                        <td>{item.id}</td>
                                        <td id="chittiName"><Link
                                            to={`/chittiList/${item.chittiCode}`}>{item.chittiName}</Link></td>
                                        <td>{item.chittiOwner}</td>
                                        <td id="chittiCode"><Link
                                            to={`/chittiList/${item.chittiCode}`}>{item.chittiCode}</Link></td>
                                        <td id="status">{item.status}</td>
                                        <td>{item.startDate}</td>
                                        <td>{item.endDate}</td>
                                        <td>{item.totalMonths}</td>
                                        <td>{item.totalMembers}</td>
                                        <td>{item.interest}</td>
                                        <td>{item.adminCommission}</td>
                                        <td>{item.chittiAmount}</td>
                                        <td>{item.monthlyPremium}</td>
                                        <td>
                                            <a href="#!" className="edit" data-toggle="modal"
                                               data-target="#editChittiModal" onClick={this.chitDetails}
                                               id="editModalHrefId"><i
                                                className="material-icons" data-toggle="tooltip"
                                                title="Edit">&#xE254;</i></a>
                                            <a href={"#deleteEmployeeModal"} className="delete" data-toggle="modal"><i
                                                className="material-icons" data-toggle="tooltip"
                                                title="Delete">&#xE872;</i></a>
                                        </td>
                                    </tr>))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    {/*Edit Modal HTML*/}

                    <div id="editChittiModal" className="modal fade">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Edit Chit Details</h4>
                                    <br/>
                                    <button type="button" className="close" data-dismiss="modal" id="editCloseButton"
                                            aria-hidden="true">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <form id="updaterChitForm">
                                        <div className="form-group">
                                            <label>ID</label>
                                            <input type="text" className="form-control custom-control custom-checkbox"
                                                   id="editID" required readOnly={true}/>
                                        </div>

                                        <div className="form-group">
                                            <label>Chitti Name</label>
                                            <input type="text" className="form-control" id="editChittiName"/>
                                        </div>
                                        <div className="form-group">
                                            <label>Chitti Owner</label>
                                            <input type="text" className="form-control" id="editChittiOwner" required
                                                   readOnly={true}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Chitti Code</label>
                                            <input type={"text"} className="form-control" id="editChittiCode"
                                                   required readOnly={true}/>
                                        </div>
                                        <div className="form-group">
                                            <label>status</label>
                                            <select type="text" className="form-control" id="editStatus" required>
                                                <option>NOT_STARTED</option>
                                                <option>ON_BOARDING</option>
                                                <option>LIVE</option>
                                                <option>COMPLETED</option>
                                                <option>PAUSED_FOR_VALID_REASON</option>
                                                <option>HAVING_ISSUES_PAUSED</option>
                                                <option>FINAL_STAGE</option>
                                                <option>CLOSED</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <input type="text" className="form-control" id="editStartDate"/>
                                        </div>


                                        <div className="form-group">
                                            <label>End Date</label>
                                            <input type="text" className="form-control" id="editEndDate"/>
                                        </div>


                                        <div className="form-group">
                                            <label>No Of Months</label>
                                            <input type="number" className="form-control" id="editTotalMonths"/>
                                        </div>


                                        <div className="form-group">
                                            <label>Total Members</label>
                                            <input type="number" className="form-control" id="editTotalMembers"/>
                                        </div>


                                        <div className="form-group">
                                            <label>Rate Of Interest</label>
                                            <input type="text" className="form-control" id="editInterest"/>
                                        </div>


                                        <div className="form-group">
                                            <label>Admin Commission</label>
                                            <input type="text" className="form-control" id="editAdminCommission"/>
                                        </div>

                                        <div className="form-group">
                                            <label>Chitti Amount</label>
                                            <input type="text" className="form-control" id="editChittiAmount"/>
                                        </div>

                                        <div className="form-group">
                                            <label>Monthly Premium</label>
                                            <input type="text" className="form-control" id="editMonthlyPremium"/>
                                        </div>

                                    </form>

                                </div>

                                <div className="modal-footer">

                                    <input type="button" className="btn btn-default" data-dismiss="modal"
                                           value="Cancel"/>

                                    <input type="button" className="btn btn-info" value="Save" id="updateUser"
                                           onClick={this.handleUpdateUser}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Delete Modal HTML*/}

                    <div id="deleteChitModal" className="modal fade">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <form>
                                    <div className="modal-header">
                                        <h4 className="modal-title">Delete Chit</h4>
                                        <button type="button" className="close" data-dismiss="modal"
                                                aria-hidden="true">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you want to delete these Records?</p>
                                        <p className="text-warning"><small>This action cannot be undone.</small></p>
                                    </div>
                                    <div className="modal-footer">
                                        <input type="button" className="btn btn-default" data-dismiss="modal"
                                               value="Cancel"/>
                                        <input type="submit" className="btn btn-danger" value="Delete"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>

        );
    }


    loadResults = () => {
        // ********************REST CALL TO SERVICE***************
        const url = process.env.REACT_APP_BACKEND_HOST;
        console.log("url :" + url);
        let self = this;
        axios.get(url + '/girify/chits', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                "Access-Control-Max-Age": "3600",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "Content-Type, Authorization, authorization, set_cookie",
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": inMemoryJWTService.getToken()
            }
        }, {withCredentials: true})
            .then(function (response) {

                if (response.status === 200) {
                    let data = response.data;
                    console.log("chit results : " + data);

                    if (data !== undefined && data != null) {
                        console.log("am in success block :" + response.status);
                        self.updateResults(data);
                        self.updateGraphics();
                    }

                } else {
                    console.log("am in danger block")
                }

            })
            .catch(function (error) {
                console.log(error);
            });
        // ***********************************
    }

    updateResults = (data) => {
        this.setState({
            chits: data, loading: true
        });
    }

    updateGraphics = () => {

        $("tr").each(function () {
            const statusValue = $(this).find("td:eq(4)").text();
            console.log(statusValue)

            if (statusValue === 'NOT_STARTED') {
                $(this).find("td:eq(4)").css('font-weight', 'bold');
                $(this).find("td:eq(4)").css('color', 'Orange');
            }

            if (statusValue === 'ON_BOARDING') {
                $(this).addClass("blink");
                $(this).css('font-weight', 'bold');
                $(this).find("td:eq(4)").css('color', 'purple');
            }

            if (statusValue === 'LIVE') {
                $(this).css("background", "lightgreen");
                $(this).css("-webkit-text-fill-color", "Black");
                $(this).find("td:eq(4)").css('font-weight', 'bold');
                $(this).find("td:eq(4)").css("-webkit-text-fill-color", "Yellow");
            }
        });
    }

    handleUpdateUser = () => {
        let self = this;
        let editChittiName = $('#editChittiName').val();

        const updateData = {
            id: $('#editID').val(),
            chittiName: editChittiName,
            chittiOwner: $('#editChittiOwner').val(),
            chittiCode: $('#editChittiCode').val(),
            status: $('#editStatus').val(),
            startDate: $('#editStartDate').val(),
            endDate: $('#editEndDate').val(),
            totalMembers: $('#editTotalMembers').val(),
            interest: $('#editInterest').val(),
            chittiAmount: $('#editChittiAmount').val(),
            monthlyPremium: $('#editMonthlyPremium').val(),
            adminCommission: $('#editAdminCommission').val(),
            totalMonths: $('#editTotalMonths').val()
        };

        // ******************** REST CALL TO SERVICE***************
        let url = process.env.REACT_APP_BACKEND_HOST;
        // let updateMsg = editChittiName + " details updated successfully.";
        axios.put(url + '/girify/chits', updateData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                    "Access-Control-Max-Age": "3600",
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Allow-Headers": "Content-Type, Authorization, authorization, set_cookie",
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": inMemoryJWTService.getToken()
                }
            }
        ).then(function (response) {
            self.captureError("User updated successfully.");
        })
            .catch(function (error) {
                console.log("error:", error)
                var msg = ""
                if (error.code === 'ERR_NETWORK') {
                    msg = 'Girify Backend Services are Unavailable. Please try again later.'
                } else {
                    msg = error.response.data.errorMessage;
                }
                self.captureError(msg);
            });
        $("#editCloseButton").click();
        this.loadResults()
    }

    renderStatusAndMessage = () => {
        this.state.updateMessage !== "" &&
        (<div className="col-lg-12"> {this.state.updateMessage} </div>)
    }
}

export default ChittiList;

// https://www.tutorialrepublic.com/codelab.php?topic=bootstrap&file=crud-data-table-for-database-with-modal-form   -- referred from this