import React from "react";
import "../base/Logout.css";
import inMemoryJWTService from "../base/InMemoryJWTService";
import {FaHome} from 'react-icons/fa';
import {Link, Navigate} from "react-router-dom";

class LogoutButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (event) => {
        event.preventDefault();
        inMemoryJWTService.deleteToken();
        this.setState({disabled: true});
        window.location.replace("/login")
    };

    render() {
        return (
            <div className="m-4">
                <nav className="navbar navbar-expand navbar-light bg-light">
                    <div className="container-fluid">
                        <div className="logo">
                            <Link to="/Menu">
                                {/* You can replace 'Link' with your routing solution */}
                                <FaHome size={30}/> {/* Adjust the size as needed */}
                            </Link>
                        </div>
                    </div>

                    <div id="navbarCollapse" className="collapse navbar-collapse">

                        <ul className="nav navbar-nav ms-auto">
                            <li className="nav-item dropdown">
                                {/*Add profile image later*/}
                                <a href="#" className="nav-link dropdown-toggle"
                                   data-bs-toggle="dropdown">Profile</a>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item btn btn-primary"
                                            id="logout-button"
                                            onClick={this.handleClick}
                                            disabled={this.state.disabled}
                                    >
                                        Log out
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        )
            ;
    }
}

export default LogoutButton;
