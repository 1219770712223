import {Navigate} from "react-router-dom";
import inMemoryJWTService from "./InMemoryJWTService";

const ProtectedForLogin = ({children}) => {
    const isLoggedOut = inMemoryJWTService.getToken() == null
    console.log("isUserLoggedOut :", isLoggedOut)

    if (isLoggedOut) {
        console.log("Not enough user access to this url.");
        return <Navigate to="/login" replace/>;
    }
    return children;
};
export default ProtectedForLogin;