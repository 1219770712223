import React, {useState} from "react";

import "../base/styles.css";
import $ from 'jquery';
import {NavLink} from "react-router-dom";
import axios from "axios";
import {FaHome} from "react-icons/fa";

function RegisterUser() {
    const initialvalues = {
        status: false,
        message: ""
    };
    const [statusMessage, setStatusMessage] = useState(initialvalues);

    function getSuccessIcon() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                    className="bi bi-check-circle-fill" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
        </svg>;
    }

    function getErrorIcon() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red"
                    className="bi bi-x-circle-fill" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
        </svg>;
    }

    const renderStatusAndMessage = (statusMessage) => (
        statusMessage.message !== "" && (
            (statusMessage.status === true && <div className="col-lg-12">
                {statusMessage.message}
                {getSuccessIcon()}
                <div/>
                <NavLink to="/login">Login with new User</NavLink>
            </div>)
            ||
            (statusMessage.status === false && <div className="col-lg-12">
                {statusMessage.message}
                {getErrorIcon()}
            </div>)
        )
    );

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();

        let name = $("#name").val();
        let mobile = $("#mobile").val();
        let email = $("#email").val();
        let uname = $("#uname").val();
        let password = $("#password").val();
        let address = $("#address").val();
        let dob = $("#dob").val();

        let userType = []
        userType.push($("#userType").val());

        let adminIds = []
        $("#adminMemberIds li").each(function (i, item) {
            // Push the text of the current list item to the 'adminMemberIds' array
            adminIds.push(item.innerText);
        });

        const data = {
            mobile: mobile,
            email: email,
            username: uname,
            password: password,
            name: name,
            address: address,
            dob: dob,
            roles: userType,
            adminMemberIds: adminIds
        };

        const securityHeaders = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
            "Access-Control-Max-Age": "3600",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Headers": "Content-Type, authorization, set_cookie",
            "Accept": "application/json",
            "authorization": "some token",
            "Content-Type": "application/json"
        };

        console.log(data);

        // ******************** REST CALL TO SERVICE***************
        let url = process.env.REACT_APP_SECURITY_HOST;
        axios.post(url + '/api/auth/signup', data, {securityHeaders})
            .then(function (response) {
                console.log(response);

                if (response.data.status === 'success') {
                    console.log("User created successfully." + response.data.memberId);
                    setStatusMessage({
                        status: true,
                        message: "User : " + name + " Created Successfully with MemberId " + response.data.memberId
                    });
                    $("#createNewUserForm").hide();
                } else {
                    console.log("Invalid user data. ");
                    setStatusMessage({status: false, message: response.data + ":" + response.status});
                }
            })
            .catch(function (error) {
                console.log(error);
                var msg = ""
                if (error.code === 'ERR_NETWORK') {
                    msg = 'Girify Backend Services are Unavailable. Please try again later.'
                } else {
                    msg = error.response.data.errorMessage;
                }
                setStatusMessage({
                    status: false,
                    message: msg
                });
            });
        // ***********************************
        console.log(statusMessage.message);
    }

    const renderForm = (
        <div className="table-responsive">
            <div className="form"> {renderStatusAndMessage(statusMessage)}
                <form onSubmit={handleSubmit}>
                    <div id="createNewUserForm" className="col-sm-8">
                        <fieldset style={{color: "blue", fontWeight: "500", alignContent: "normal"}}>
                            <div className="form-outline mb-8 row">
                                <label htmlFor="name" className="col-md-2 col-form-label col-form-label-lg">Full
                                    Name</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control form-control-lg" id="name"
                                           placeholder="User Full Name"/>
                                </div>
                            </div>

                            <div className="form-outline mb-8 row">
                                <label htmlFor="mobile"
                                       className="col-sm-2 col-form-label col-form-label-lg">Mobile Number</label>
                                <div className="col-sm-8">
                                    <input type="number" className="form-control form-control-lg" id="mobile"
                                           placeholder="Mobile Number"/>
                                </div>
                            </div>

                            <div className="form-outline mb-8 row">
                                <label htmlFor="userType" className="col-sm-2 col-form-label col-form-label-lg">OnBoarding
                                    As</label>
                                <div className="col-sm-8">
                                    <select type="text" className="form-control form-control-lg" id="userType"
                                            onChange={shouldDisplayAdminMobile}
                                            required>
                                        <option value={"user"}>User</option>
                                        <option value={"admin"}>Owner/Admin</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-outline mb-8 entry input-group" id={"adminMobileDiv"}>
                                <label htmlFor=" addList"
                                       className=" col-sm-2 col-form-label col-form-label-lg">Admin's Id</label>
                                <input className=" col-sm-5 form-control form-control-sm" type="text" id="addList"
                                       placeholder="Add Multiple Admin's MemberIds if you are with multiple Chits"/>
                                <input type="button" name="add"
                                       className="col-sm-2 form-control-sm btn btn-primary" value="ADD"
                                       id={"addMobileBtn"}
                                       onClick={loadPhoneNumbers}/>
                                <ol className="List" id="adminMemberIds"></ol>
                            </div>

                            <div className="form-outline mb-8 row">
                                <label htmlFor="email"
                                       className="col-sm-2 col-form-label col-form-label-lg">Email</label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control form-control-lg" id="email"
                                           placeholder="Email"/>
                                </div>
                            </div>

                            <div className="form-outline mb-8 row">
                                <label htmlFor="uname"
                                       className="col-sm-2 col-form-label col-form-label-lg">Username</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control form-control-lg" id="uname"
                                           placeholder="Username"/>
                                </div>
                            </div>

                            <div className="form-outline mb-8 row">
                                <label htmlFor="password"
                                       className="col-sm-2 col-form-label col-form-label-lg">Password</label>
                                <div className="col-sm-8">
                                    <input type="password" className="form-control form-control-lg" id="password"
                                           placeholder="Password"/>
                                </div>
                            </div>

                            <div className="form-outline mb-8 row">
                                <label htmlFor="dob"
                                       className="col-sm-2 col-form-label col-form-label-lg">Date Of Birth</label>
                                <div className="col-sm-8">
                                    <input type="date" className="form-control form-control-lg" id="dob"
                                           placeholder="dob"/>
                                </div>
                            </div>

                            <div className="form-outline mb-8 row">
                                <label htmlFor="address"
                                       className="col-sm-2 col-form-label col-form-label-lg">Address</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control form-control-lg" id="address"
                                           placeholder="address"/>
                                </div>
                            </div>

                            <div className="form-outline mb-3 row"/>

                            <button type="submit" className="btn btn-primary" id="createUserButton">Onboard User/Admin
                            </button>

                        </fieldset>
                    </div>
                </form>
            </div>
        </div>
    );

    function shouldDisplayAdminMobile() {
        const $userType = $("#userType");
        const $adminMobile = $("#adminMobileDiv");

        console.log($userType.val())

        if ($userType.val() === 'user') {
            $adminMobile.show();
        } else {
            $adminMobile.hide();
            $("#adminMemberIds li").each(function () {
                $(this).remove();
            });
        }
    }

    function loadPhoneNumbers() {
        var content = $('#addList').val();
        if (content !== undefined && content !== '') {
            var fixingContent = '<li>' + content + '<input type="button" onclick="$(this).closest(\'#adminMemberIds li\').remove()" value="X"></input></li>';
            $('#adminMemberIds').append(fixingContent);
            $('#addList').val('')
        }
    }

    return (
        <div className="App">
            <div className="logo">
                {/* You can replace 'Link' with your routing solution */}
                <a href="/Login">
                    <FaHome size={30} /> {/* Adjust the size as needed */}
                </a>
            </div>
            <div className="title col-form-label" style={{color: "CaptionText", fontWeight: "500"}}>
                    GIRIFY -
                Onboarding User
            </div>
            {renderForm}
        </div>
    );
};
export default RegisterUser;