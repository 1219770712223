import React, {useState} from "react";

import "../base/styles.css";
import $ from 'jquery';
import {NavLink, useNavigate} from "react-router-dom";
import axios from "axios";
import inMemoryJWTService from "../base/InMemoryJWTService";
import LogoutButton from "../login/Logout";
import Footer from "../base/Footer";

function CreateChitti() {
    const navigate = useNavigate();

    const initialvalues = {
        status: false,
        message: ""
    };
    const [statusMessage, setStatusMessage] = useState(initialvalues);

    function getSuccessIcon() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                    className="bi bi-check-circle-fill" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
        </svg>;
    }

    function getErrorIcon() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red"
                    className="bi bi-x-circle-fill" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
        </svg>;
    }

    const renderStatusAndMessage = (statusMessage) => (
        statusMessage.message !== "" && (
            (statusMessage.status === true && <div className="col-lg-12">
                {statusMessage.message}
                {getSuccessIcon()}
                <div/>
                <NavLink to="/chittiList">Loading in 3 seconds</NavLink>
            </div>)
            ||
            (statusMessage.status === false && <div className="col-lg-12">
                {statusMessage.message}
                {getErrorIcon()}
            </div>)
        )
    );

    const handleSubmit = (event) => {
        //     //Prevent page reload
        event.preventDefault();

        const data = {
            chittiName: $("#chittiName").val(),
            startDate: $("#startDate").val(),
            endDate: $("#endDate").val(),
            totalMonths: $("#totalMonths").val(),
            totalMembers: $("#totalMembers").val(),
            adminCommission: $("#adminCommission").val(),
            interestRate: $("#interest").val(),
            chittiAmount: $("#chittiAmount").val(),
            monthlyPremium: $("#monthlyPremium").val()
        };
        console.log(data);

        // ******************** REST CALL TO SERVICE***************
        const url = process.env.REACT_APP_BACKEND_HOST;
        console.log("url :" + url);
        axios.post(url + '/girify/chits', data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                    "Access-Control-Max-Age": "3600",
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Allow-Headers": "Content-Type, Authorization, authorization, set_cookie",
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": inMemoryJWTService.getToken()
                }
            }
        ).then(function (response) {
            console.log(response);

            if (response.data.status === 'success') {
                console.log("New Chitti created successfully." + response.data.chittiId);
                setStatusMessage({
                    status: true,
                    message: "New Chitti Created Successfully with ChittiId " + response.data.chittiId
                });
                setTimeout(() => {
                    $("#createNewChittiForm").hide();
                    navigate("/chittiList");
                }, 3000);
            } else {
                console.log("Invalid Chitti data. ");
                setStatusMessage({status: false, message: response.data + ":" + response.status});
            }
        })
            .catch(function (error) {
                console.log(error);
                setStatusMessage({
                    status: false,
                    message: error.response.data.errorMessage
                });
            });

        // ***********************************

        console.log(statusMessage.message);
    }

    function updateAmount() {

        // var chittiAmount = $('#chittiAmount').val();
        // var monthlyPremium = $('#monthlyPremium').val();
        // var totalMembers = $('#totalMembers').val();
        //
        // var totalMonths = $('#totalMonths').val();
        //
        // if ((chittiAmount !== "" && totalMonths !== "") || (monthlyPremium !== "" && chittiAmount !== "")) {
        //
        //     if (chittiAmount !== "" && totalMonths !== "") {
        //         $('#monthlyPremium').val(chittiAmount / totalMonths);
        //     } else if (chittiAmount !== "" && monthlyPremium !== "") {
        //         $('#totalMonths').val(chittiAmount / monthlyPremium);
        //     }
        //
        //     $('#adminCommission').val(chittiAmount * 0.5 / 100);
        //     $('#totalMembers').val(totalMonths);
        // }
    }

    function updateEndDate() {
        let totalMonths = $('#totalMonths').val();
        var startDateText = $('#startDate').val();
        let startDate = new Date(Date.parse(startDateText));

        if (startDate !== undefined && totalMonths !== "" && startDate !== "") {
            let endDate = new Date(startDate.setMonth(startDate.getMonth() + totalMonths));
            console.log("End Date :", endDate.toISOString().split('T')[0]);
            $('#endDate').val(endDate.toISOString().split('T')[0]);
        }
    }


    const renderForm = (
        <div className="form"> {renderStatusAndMessage(statusMessage)}
            <form onSubmit={handleSubmit}>
                <div id="createNewChittiForm" className="col-sm-12">
                    <fieldset style={{color: "blue", fontWeight: "500", alignContent: "center"}}>

                        <div className="form-outline mb-8 row">
                            <label htmlFor="chittiName" className="col-sm-2 col-form-label col-form-label-lg">Chitti
                                Name</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control form-control-lg" id="chittiName"
                                       placeholder="Chitti Name"/>
                            </div>
                        </div>

                        <div className="form-outline mb-12 row">
                            <label htmlFor="startDate"
                                   className="col-sm-2 col-form-label col-form-label-lg">Start Date</label>
                            <div className="col-sm-4">
                                <input type="date" className="form-control form-control-lg" id="startDate"
                                       onBlur={updateEndDate}
                                       placeholder="Start Date"/>
                            </div>
                        </div>

                        <div className="form-outline mb-12 row">
                            <label htmlFor="totalMonths"
                                   className="col-sm-2 col-form-label col-form-label-lg">Total Months</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control form-control-lg" id="totalMonths"
                                       onBlur={updateEndDate}
                                       placeholder="Total Months for this Chit"/>
                            </div>
                        </div>

                        <div className="form-outline mb-12 row">
                            <label htmlFor="endDate"
                                   className="col-sm-2 col-form-label col-form-label-lg">End Date</label>
                            <div className="col-sm-4">
                                <input type="date" className="form-control form-control-lg" id="endDate"
                                       placeholder="End Date"/>
                            </div>
                        </div>

                        <div className="form-outline mb-12 row">
                            <label htmlFor="totalMembers"
                                   className="col-sm-2 col-form-label col-form-label-lg">Total Members In This
                                Chitti</label>
                            <div className="col-sm-4">
                                <input type="number" className="form-control form-control-lg" id="totalMembers"
                                       onBlur={updateAmount}
                                       placeholder="number of members"/>
                            </div>
                        </div>

                        <div className="form-outline mb-12 row">
                            <label htmlFor="adminCommission"
                                   className="col-sm-2 col-form-label col-form-label-lg">Monthy Commission</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control form-control-lg" id="adminCommission"
                                       onBlur={updateAmount}
                                       placeholder="Monthly Admin Commission (INR)"/>
                            </div>
                        </div>

                        <div className="form-outline mb-12 row">
                            <label htmlFor="chittiAmount"
                                   className="col-sm-2 col-form-label col-form-label-lg">Chitti Sum Assured</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control form-control-lg" id="chittiAmount"
                                       onBlur={updateAmount}
                                       placeholder="Chitti Sum Assured Amount"/>
                            </div>
                        </div>

                        <div className="form-outline mb-12 row">
                            <label htmlFor="monthlyPremium"
                                   className="col-sm-2 col-form-label col-form-label-lg">Monthly Premium</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control form-control-lg" id="monthlyPremium"
                                       placeholder="Chitti Monthly Premium Amount"/>
                            </div>
                        </div>

                        <div className="form-outline mb-12 row">
                            <label htmlFor="interest"
                                   className="col-sm-2 col-form-label col-form-label-lg">Chitti Interest (%)</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control form-control-lg" id="interest"
                                       placeholder="Interest On Acquired Chitti Amount (Monthly)"/>
                            </div>
                        </div>

                        <div className="form-outline mb-3 row"/>
                        <button type="submit" className="btn btn-primary" id="createChittiButton">Create Chitti</button>

                    </fieldset>
                </div>
            </form>
        </div>
    );

    return (
        <div className="App">
            <LogoutButton/>
            <div className="title" style={{color: "CaptionText", fontWeight: "500"}}>Create New Chitti</div>
            {renderForm}
            <Footer />
        </div>
    );
};
export default CreateChitti;