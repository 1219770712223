const chittiDetailsInfo = (chit) => (
    <div id="chittiDetailsId">
        <form id="chittiFormId" className="row g-1 col-sm-12">
            <div className="col-md-12">
                <div className="title col-form-label"
                     style={{
                         textAlign: "center",
                         color: "CaptionText",
                         fontWeight: "500"
                     }}> {chit.chittiName} : Chitti
                    Details
                </div>
            </div>

            <div className="col-md-3">
                <label htmlFor="chittiCode" className="col-md-12 col-form-label col-form-label-lg">Chitti
                    Code</label>
                <input type="text" className="form-control form-control-lg" id="chittiCode" readOnly={true}
                       value={chit.chittiCode}/>
            </div>
            <div className="col-md-3">
                <label htmlFor="chittiName" className="col-md-12 col-form-label col-form-label-lg">Chitti
                    Name</label>
                <input type="text" className="form-control form-control-lg" id="chittiName" readOnly={true}
                       value={chit.chittiName}/>
            </div>
            <div className="col-md-3">
                <label htmlFor="status" className="col-md-12 col-form-label col-form-label-lg">Status</label>
                <input type="text" className="form-control form-control-lg" id="status" readOnly={true}
                       value={chit.status}/>
            </div>

            <div className="col-md-3">
                <label htmlFor="startDate" className="col-md-12 col-form-label col-form-label-lg">Start
                    Date</label>
                <input type="date" className="form-control form-control-lg" id="startDate" readOnly={true}
                       value={chit.startDate}/>
            </div>
            <div className="col-md-3">
                <label htmlFor="endDate" className="col-md-12 col-form-label col-form-label-lg">End Date</label>
                <input type="date" className="form-control form-control-lg" id="endDate" readOnly={true}
                       value={chit.endDate}/>
            </div>
            <div className="col-md-3">
                <label htmlFor="totalMonths" className="col-md-12 col-form-label col-form-label-lg">In
                    Months</label>
                <input type="text" className="form-control form-control-lg" id="totalMonths" readOnly={true}
                       value={chit.totalMonths}/>
            </div>

            <div className="col-md-3">
                <label htmlFor="totalMembers" className="col-md-12 col-form-label col-form-label-lg">Total
                    Members</label>
                <input type="text" className="form-control form-control-lg" id="totalMembers" readOnly={true}
                       value={chit.totalMembers}/>
            </div>
            <div className="col-md-3">
                <label htmlFor="ownerInterest" className="col-md-12 col-form-label col-form-label-lg">Interest-Rate
                    On
                    Taken Loan</label>
                <input type="test" className="form-control form-control-lg" id="ownerInterest" readOnly={true}
                       value={chit.interest}/>
            </div>
            <div className="col-md-3">
                <label htmlFor="savingsInterest" className="col-md-12 col-form-label col-form-label-lg">Interest-Rate
                    for
                    savings</label>
                <input type="text" className="form-control form-control-lg" id="savingsInterest" readOnly={true}
                       value={chit.interest}/>
            </div>

            <div className="col-md-3">
                <label htmlFor="adminCommission" className="col-md-12 col-form-label col-form-label-lg">Admin
                    Commission</label>
                <input type="text" className="form-control form-control-lg" id="adminCommission" readOnly={true}
                       value={chit.adminCommission}/>
            </div>
            <div className="col-md-3">
                <label htmlFor="chittiAmount" className="col-md-12 col-form-label col-form-label-lg">Chitti
                    Amount</label>
                <input type="test" className="form-control form-control-lg" id="chittiAmount" readOnly={true}
                       value={chit.chittiAmount}/>
            </div>
            <div className="col-md-3">
                <label htmlFor="monthlyPremium" className="col-md-12 col-form-label col-form-label-lg">Monthly
                    Premium</label>
                <input type="text" className="form-control form-control-lg" id="monthlyPremium" readOnly={true}
                       value={chit.monthlyPremium}/>
            </div>

            <div className="col-md-3">
                <label htmlFor="chittiOwner" className="col-md-12 col-form-label col-form-label-lg">Chitti
                    Owner</label>
                <input type="text" className="form-control form-control-lg" id="chittiOwner" readOnly={true}
                       value={chit.chittiOwner}/>
            </div>
        </form>
    </div>
)

export default chittiDetailsInfo;