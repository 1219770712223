import {Navigate} from "react-router-dom";
import inMemoryJWTService from "./InMemoryJWTService";

const Protected = ({isAuthorized, children}) => {
    const isLoggedOut = inMemoryJWTService.getToken() == null
    console.log("isUserLoggedIn :", !isLoggedOut)
    console.log("isAuthorized :", isAuthorized)

    if (isLoggedOut) {
        console.log("Not enough user access to this url.");
        return <Navigate to="/login" replace/>;
    }

    if (!(isAuthorized || inMemoryJWTService.getIsAdmin())) {
        console.log("Not enough user access to this url.");
        return <Navigate to="/chittiMenu" replace/>;
    }

    console.log("Authorized user access to this url.");

    return children;
};

const onPageReload = () => {
    // eslint-disable-next-line no-undef
    $(window).on('beforeunload', function () {
        console.log("on page reload",inMemoryJWTService.getToken())
    });
}

export default Protected;