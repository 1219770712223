const inMemoryJWTManager = () => {
    let inMemoryJWT = null;
    let isAdmin = false;

    const getToken = () => inMemoryJWT;
    const getIsAdmin = () => isAdmin;

    const setToken = (token) => {
        inMemoryJWT = token;
        return true;
    };

    const setAdmin = (adminStatus) => {
        isAdmin = adminStatus;
        return true;
    };

    const deleteToken = () => {
        inMemoryJWT = null;
        return true;
    };

    return {
        getToken,
        setToken,
        deleteToken,
        getIsAdmin,
        setAdmin
    };
};

export default inMemoryJWTManager();
