// Footer.js
import React from 'react';
import './Logout.css';

const Footer = () => {
    return (
            <footer>
                {/* Your footer content goes here */}
                <p>
                    2024 GIRIFY</p>
                <a href="../../../LICENSE.txt" target="_blank" rel="noopener noreferrer">MIT</a>© Chiranjeevi Bairaagoni
            </footer>
    );
};

export default Footer;
