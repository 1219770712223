import React from "react";

import "../base/list.css";
import axios from "axios";
import $ from "jquery";
import LogoutButton from "../login/Logout";
import inMemoryJWTService from "../base/InMemoryJWTService";
import Footer from "../base/Footer";

class UsersList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            users: [], loading: false, updateMessage: ""
        };
    }

    componentDidMount() {
        this.loadResults();
        console.log(this.state.loading);
        this.updateGraphics();
    }

    userDetail = (ele) => {
        console.log("ele on clicked", ele)

        //the <tr> variable is use to set the parentNode from "ele
        var tr = ele.target.parentNode.parentNode.parentNode;
        console.log("tr", tr)
        //I get the value from the cells (td) using the parentNode (var tr)

        var image = '';
        // tr.cells[0].children.item(0);
        var name = tr.cells[1].textContent;
        var email = tr.cells[2].textContent;
        var address = tr.cells[3].textContent;
        var phone = tr.cells[4].textContent;
        // var dob = new Date(tr.cells[5].textContent).toLocaleString('en-GB').split(',')[0];
        var linkedOn = new Date(tr.cells[5].textContent).toLocaleString('en-GB').split(',')[0];
        // adminMemberIds - [6]
        var enabled = tr.cells[7].childNodes[0];
        var accountNonLocked = tr.cells[8].childNodes[0];
        var accountNonExpired = tr.cells[9].childNodes[0];
        var credentialsNonExpired = tr.cells[10].childNodes[0];

        //Prefill the fields with the gathered information
        var myModal = $('#editEmployeeModal');

        $('h4.modal-title', myModal).html('Edit User Data: ' + name);
        $('#editName', myModal).val(name);
        $('#editImage', myModal).val(image);
        $('#editEmail', myModal).val(email);
        $('#editAddress', myModal).val(address);
        $('#editPhone', myModal).val(phone);
        $('#editLinkedOn', myModal).val(linkedOn);
        // $('#editDob', myModal).val(dob);

        var adminNames = tr.cells[6].childNodes[0].childNodes;
        var adminNamesArr = []
        console.log(adminNames)
        $('#editAdminIds li', myModal).remove()
        for (var item in adminNames) {
            if (adminNames[item].id !== undefined) {
                adminNamesArr.push('<li>' + adminNames[item].id + '</li>');
            }
        }
        $('#editAdminIds', myModal).append(adminNamesArr);
        console.log(adminNamesArr)

        $('#editEnabled').prop("checked", enabled.checked);
        $('#editAccountNonLocked').prop("checked", accountNonLocked.checked);
        $('#editAccountNonExpired').prop("checked", accountNonExpired.checked);
        $('#editCredentialsNonExpired').prop("checked", credentialsNonExpired.checked);
    }

    loadPhoneNumbers = () => {
        var content = $('#addList').val();
        var fixingContent = '<li>' + content + '<input type="button" onclick="$(this).closest(\'#editAdminIds li\').remove()" value="X"></input></li>';
        $('#editAdminIds').append(fixingContent);
    }
    filterResults = () => {
        var input = document.getElementById('myInput');
        var filter = input.value.toUpperCase();

        console.log(filter)

        $("tbody tr").each(function () {
            const name = $(this).find("td:eq(1)").text();
            const mobile = $(this).find("td:eq(4)").text();
            const admins = $(this).find("td:eq(6)").text();
            console.log(name)
            // Loop through all list items, and hide those who don't match the search query
            if ((name.toUpperCase().indexOf(filter) > -1)
                || (admins.toUpperCase().indexOf(filter) > -1)
                || (mobile.toUpperCase().indexOf(filter) > -1)) {
                $(this).css("display", "");
            } else {
                $(this).css("display", "none");
            }
        });
    }

    render() {
        return (
            <div className="table-responsive">
                <div className="App">
                    <LogoutButton/>
                    <div className="container-xxl">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-md-16">
                                        <h2>Manage <b>GIRIFY Users</b>
                                        </h2>
                                        {/*<h5>{this.renderStatusAndMessage(this.state.updateMessage)}</h5>*/}
                                    </div>
                                    <div className="col-sm-16">
                                        <a href={"#addEmployeeModal"} className="btn btn-success"
                                           data-toggle="modal"><i
                                            className="material-icons">&#xE147;</i>
                                            <span>Add New User</span></a>

                                        {/*<a href={"#deleteEmployeeModal"} className="btn btn-danger"*/}
                                        {/*   data-toggle="modal"><i*/}
                                        {/*    className="material-icons">&#xE15C;</i> <span>Delete</span></a>*/}

                                    </div>
                                </div>
                            </div>
                            <div className="row table-responsive">
                                <input type="text" id="myInput" onKeyUp={this.filterResults}
                                       placeholder="Search for names or admins or status ..."/>
                            </div>
                            <div></div>
                            <table className="table table-responsive table-striped table-hover" id={"userTable"}>
                                <thead className="thead-dark">
                                <tr>
                                    {/*        <th>*/}
                                    {/*<span className="custom-checkbox">*/}
                                    {/*	<input type="checkbox" id="selectAll"/>*/}
                                    {/*	<label htmlFor="selectAll"></label>*/}
                                    {/*</span>*/}
                                    {/*        </th>*/}
                                    <th>Photo</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    {/*<th>Address</th>*/}
                                    <th>Phone</th>
                                    {/*<th>DateOfBirth</th>*/}
                                    {/*<th>UserJoiningDate</th>*/}
                                    <th>My Admins</th>
                                    <th>Enabled</th>
                                    <th>Account Non-locked</th>
                                    <th>Account Non-Expired</th>
                                    <th>Credentials Non-Expired</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>

                                {this.state.loading && this.state.users.map(item => (<tr>
                                    {/*        <td>*/}
                                    {/*<span className="custom-checkbox">*/}
                                    {/*<input type="checkbox" id={item.mobile} name="options[]" value="1"/>*/}
                                    {/*<label htmlFor={item.mobile}></label>*/}
                                    {/*</span>*/}
                                    {/*        </td>*/}
                                    <td>Image</td>
                                    <td id={"name"}>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td style={{display: "none"}}><textarea aria-multiline={true}
                                                                            readOnly={true}>{item.address}</textarea>
                                    </td>
                                    <td id={"mobile"}>{item.mobile}</td>

                                    {/*<td>{item.dob}</td>*/}

                                    <td style={{display: "none"}}>{item.joinedAt}</td>

                                    <td>
                                        <ul className="List" id="adminMemberIds">
                                            {item.adminMemberIds.map((a) =>
                                                <li id={a.adminId}>{a.adminUserName}</li>
                                            )}
                                        </ul>
                                    </td>


                                    <td>
                                        <input type="checkbox" id={item.mobile}
                                               value={item.enabled} checked={item.enabled} readOnly={true}/>
                                        <label htmlFor={item.mobile}></label>
                                    </td>
                                    <td>
                                        <input type="checkbox" id={item.mobile} checked={item.accountNonLocked}
                                               value={item.accountNonLocked}/>
                                        <label htmlFor={item.mobile}></label>
                                    </td>
                                    <td>
                                        <input type="checkbox" id={item.mobile} checked={item.accountNonExpired}
                                               value={item.accountNonExpired} readOnly={true}/>
                                        <label htmlFor={item.mobile}></label>
                                    </td>
                                    <td>
                                        <input type="checkbox" id={item.mobile} checked={item.credentialsNonExpired}
                                               value={item.credentialsNonExpired}/>
                                        <label htmlFor={item.mobile}></label>
                                    </td>
                                    <td>
                                        <a href="#!" className="edit" data-toggle="modal"
                                           data-target="#editEmployeeModal" onClick={this.userDetail}
                                           id="editModalHrefId"><i
                                            className="material-icons" data-toggle="tooltip"
                                            title="Edit">&#xE254;</i></a>

                                        <a href={"#deleteEmployeeModal"} className="delete" data-toggle="modal"><i
                                            className="material-icons" data-toggle="tooltip"
                                            title="Delete">&#xE872;</i></a>
                                    </td>
                                </tr>))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                {/*Edit Modal HTML*/}

                <div id="editEmployeeModal" className="modal fade">
                    <div className="modal-dialog">
                        <div className="modal-content">{this.renderStatusAndMessage}
                            <div className="modal-header">
                                <h4 className="modal-title">Edit User</h4>
                                <button type="button" className="close" data-dismiss="modal" id="editCloseButton"
                                        aria-hidden="true">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form id="updaterUserForm">
                                    <div className="form-group">
                                        <label>Photo</label>
                                        <input type="file" className="form-control custom-control custom-checkbox"
                                               id="editImage" required/>
                                    </div>

                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control" id="editName" readOnly={true}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control" id="editEmail" required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Address</label>
                                        <textarea className="form-control" id="editAddress" aria-multiline={true}
                                                  required></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label>Phone</label>
                                        <input type="text" className="form-control" id="editPhone" required/>
                                    </div>
                                    {/*<div className="form-group">*/}
                                    {/*    <label>DOB</label>*/}
                                    {/*    <input type="text" className="form-control" id="editDob" required/>*/}
                                    {/*</div>*/}
                                    <div className="form-group">
                                        <label>Linked On</label>
                                        <input type="text" className="form-control" id="editLinkedOn" readOnly={true}/>
                                    </div>

                                    <div className="form-group custom-control">
                                        <label>My Admins</label>
                                        <div className="entry input-group">
                                            <input className=" col-sm-6 form-control form-control-sm" type="text"
                                                   id="addList"
                                                   placeholder="Add Multiple Admin's MemberIds if you are with multiple Chits"/>
                                            <input type="button" name="add"
                                                   className="col-sm-1 form-control-sm btn btn-primary"
                                                   value="+"
                                                   id={"addMobileBtn"}
                                                   onClick={this.loadPhoneNumbers}/>
                                        </div>
                                    </div>
                                    <div className="form-group custom-control">
                                        <ol className="List" id="editAdminIds"></ol>
                                    </div>

                                    {/*Edit Four checkboxes*/}

                                    <div className="form-group custom-control">
                                        <input type="checkbox" className="form-control custom-control-input"
                                               id="editEnabled"/>
                                        <label className="custom-control-label"
                                               htmlFor="editEnabled">Enabled</label>
                                    </div>

                                    <div className="form-group custom-control">
                                        <input type="checkbox" className="form-control custom-control-input"
                                               id="editAccountNonLocked"/>
                                        <label className="custom-control-label"
                                               htmlFor="editAccountNonLocked">AccountNonLocked</label>
                                    </div>

                                    <div className="form-group custom-control">
                                        <input type="checkbox" className="form-control custom-control-input"
                                               id="editAccountNonExpired"/>
                                        <label className="custom-control-label"
                                               htmlFor="editAccountNonExpired">AccountNonExpired</label>
                                    </div>

                                    <div className="form-group custom-control">
                                        <input type="checkbox" className="form-control custom-control-input"
                                               id="editCredentialsNonExpired"/>
                                        <label className="custom-control-label"
                                               htmlFor="editCredentialsNonExpired">CredentialsNonExpired</label>
                                    </div>
                                    {/*<div className="form-group">*/}

                                    {/*            <span className="custom-checkbox">*/}
                                    {/*                <label>enabled</label>*/}
                                    {/*                <input type="checkbox" className="form-control" id="editEnabled" */}
                                    {/*                       required/>*/}
                                    {/*            </span>*/}
                                    {/*</div>*/}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <input type="button" className="btn btn-default" data-dismiss="modal"
                                       value="Cancel"/>
                                <input type="button" className="btn btn-info" value="Save" id="updateUser"
                                       onClick={this.handleUpdateUser}
                                />
                            </div>

                        </div>
                    </div>
                </div>

                {/*Delete Modal HTML*/}

                <div id="deleteEmployeeModal" className="modal fade">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form>
                                <div className="modal-header">
                                    <h4 className="modal-title">Delete Employee</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-hidden="true">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete these Records?</p>
                                    <p className="text-warning"><small>This action cannot be undone.</small></p>
                                </div>
                                <div className="modal-footer">
                                    <input type="button" className="btn btn-default" data-dismiss="modal"
                                           value="Cancel"/>
                                    <input type="submit" className="btn btn-danger" value="Delete"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }


    loadResults = () => {
        // ********************REST CALL TO SERVICE***************
        const url = process.env.REACT_APP_BACKEND_HOST;
        console.log("url :" + url);

        // let token = inMemoryJWTService.getToken();
        // const headers = {
        //     'Access-Control-Allow-Origin': '*',
        //     'Content-Type': 'application/json',
        //     "authorization": token
        // };

        let self = this;
        axios.get(url + '/girify/users', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                "Access-Control-Max-Age": "3600",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "Content-Type, Authorization, authorization, set_cookie",
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": inMemoryJWTService.getToken()
            }
        }, {withCredentials: true})
            .then(function (response) {

                if (response.status === 200) {
                    let data = response.data;
                    console.log("user results : " + data);

                    if (data !== undefined && data != null) {
                        console.log("am in success block :" + response.status);
                        self.updateResults(data);
                    }

                } else {
                    console.log("am in danger block")
                }

            })
            .catch(function (error) {
                console.log(error);
            });
        // ***********************************
    }

    updateResults = (data) => {
        this.setState({
            users: data, loading: true
        });
        this.updateGraphics();
    }

    handleUpdateUser = () => {

        let name = $('#editName').val();
        let mobile = $('#editPhone').val();
        let email = $('#editEmail').val();
        let address = $('#editAddress').val();
        let enabled = $('#editEnabled').prop("checked");
        let accountNonLocked = $('#editAccountNonLocked').prop("checked");
        let accountNonExpired = $('#editAccountNonExpired').prop("checked");
        let credentialsNonExpired = $('#editCredentialsNonExpired').prop("checked");
        let adminIds = []
        $("#editAdminIds li").each(function (i, item) {
            // Push the text of the current list item to the 'adminMemberIds' array
            adminIds.push(item.innerText);
        });

        const data = {
            mobile: mobile,
            email: email,
            username: name,
            address: address,
            adminIds: adminIds,
            enabled: enabled,
            accountNonLocked: accountNonLocked,
            accountNonExpired: accountNonExpired,
            credentialsNonExpired: credentialsNonExpired
        };

        const securityHeaders = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
            "Access-Control-Max-Age": "3600",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Headers": "Content-Type, authorization, set_cookie",
            "Accept": "application/json",
            "authorization": inMemoryJWTService.getToken(),
            "Content-Type": "application/json"
        };

        console.log(data);

        // ******************** REST CALL TO SERVICE***************
        let url = process.env.REACT_APP_SECURITY_HOST;
        let updateMsg = name + " details updated successfully.";
        axios.put(url + '/api/auth', data, {securityHeaders})
            .then(function (response) {
                console.log("User updated successfully.");
                $("#editCloseButton").click();
            })
            .catch(function (error) {
                console.log(error);
                if (error.response === null || error.response === undefined) {
                    updateMsg = error.message;
                } else {
                    updateMsg = error.response.data.errorMessage;
                }
                alert(updateMsg + ", so update cannot happen.");
            });
        this.loadResults()
        this.setState({
            updateMessage: updateMsg
        });
        // ***********************************

        // console.log(this.state.updateMessage);

        // });

    }
    updateGraphics = () => {

        $("tr").each(function () {
            var enabled = $(this).find("td:eq(7) input").prop("checked");
            var accountNonLocked = $(this).find("td:eq(8) input").prop("checked");
            var accountNonExpired = $(this).find("td:eq(9) input").prop("checked");
            var credentialsNonExpired = $(this).find("td:eq(10) input").prop("checked");

            if (!(enabled && accountNonExpired && accountNonLocked && credentialsNonExpired)) {
                $(this).addClass("blink");
                $(this).css('font-weight', 'bold');
                $(this).find("td:eq(5)").css('color', 'red');
            }
        });
    }
    renderStatusAndMessage = (message) => (
        message !== "" &&
        (
            <div className="col-lg-12"> {message} </div>
        )
    );
}


export default UsersList;

// https://www.tutorialrepublic.com/codelab.php?topic=bootstrap&file=crud-data-table-for-database-with-modal-form   -- referred from this