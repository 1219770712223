import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from "./login/Login";
import Home from "./home/Home";
import RegisterUser from "./users/RegisterUser";
import Menu from "./login/Menu";
import UserMenu from "./users/UserMenu";
import ChittiMenu from "./chitti/ChittiMenu";
import UsersList from "./users/UsersList";
import ChittiList from "./chitti/ChittiList";
import CreateChitti from "./chitti/CreateChitti";
import Protected from "./base/Protected";
import ProtectedForLogin from "./base/ProtectedForLogin";
import AdminLogin from "./login/AdminLogin";
import ChittiDetails from "./chitti/ChittiDetails";

class App extends React.Component {

    state = {
        isAdmin: false
    }

    // Callback function to handle data received from the
    //child component
    handleCallback = (isAdminStatus) => {
        // Update the name in the component's state
        this.setState({isAdmin: isAdminStatus})
    }

    render() {
        return (
            <Router>
                <Routes>

                    {/*Public Routes*/}

                    <Route path="/" element={<Home/>} exact/>
                    <Route exact path="/login" element={<Login parentCallback={this.handleCallback}/>}/>
                    <Route exact path="/adminLogin" element={<AdminLogin parentCallback={this.handleCallback}/>}/>
                    <Route path="/registerUser" element={<RegisterUser/>}/>

                    {/*Protected Routes For Valid Users*/}

                    <Route path="/chittiMenu" element={<ProtectedForLogin> <ChittiMenu/> </ProtectedForLogin>}/>
                    <Route path="/chittiList" element={<ProtectedForLogin> <ChittiList/> </ProtectedForLogin>}/>
                    <Route path="/chittiList/:chittiCode" element={<ProtectedForLogin> <ChittiDetails/> </ProtectedForLogin>}/>

                    {/*Protected Routes For Authorization*/}

                    <Route path="/menu" element={<Protected isAuthorized={this.state.isAdmin}> <Menu/> </Protected>}/>
                    <Route path="/userMenu"
                           element={<Protected isAuthorized={this.state.isAdmin}> <UserMenu/> </Protected>}/>
                    <Route path="/usersList"
                           element={<Protected isAuthorized={this.state.isAdmin}><UsersList/></Protected>}/>
                    <Route path="/createUser"
                           element={<Protected isAuthorized={this.state.isAdmin}><RegisterUser/></Protected>}/>
                    <Route path="/createChitti"
                           element={<Protected isAuthorized={this.state.isAdmin}><CreateChitti/></Protected>}/>


                    {/* Add more routes as needed */}
                </Routes>
            </Router>
        );
    }
}

export default App;
