import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";

import "../base/styles.css";
import yadagiriLogo from "../base/yadagiri-logo.png";
import $ from 'jquery';
import axios from "axios";
import {ADMIN_ROLE} from "../base/AppConstants";
import inMemoryJWTService from "../base/InMemoryJWTService";
import LoginHeaders from "./LoginHeaders";

function AdminLogin(props) {


    ///DEPLICATED THIS COMPONENT, INCLUDED INTO THE LOGIN PAGE


    const navigate = useNavigate();
    // React States
    const [errorMessages, setErrorMessages] = useState({});

    const errors = {
        uname: "invalid username",
        title: "invalid login details",
        pass: "invalid password",
        mobile: "invalid mobile number",
        otp: "invalid otp",
        mId: "invalid memberId",
        mpass: "invalid password"
    };

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();

        // Find user login info
        $(document).ready(function () {

                $("#loginButton").click(function () {

                    let uname = $("#uname").val();
                    let pass = $("#pass").val();

                    let data = {
                        username: uname,
                        password: pass
                    };

                    console.log(process.env.REACT_APP_SECURITY_HOST)

                    // ********************REST CALL TO SECURITY JWT SERVICE***************
                    axios.post(process.env.REACT_APP_SECURITY_HOST + '/api/auth/admin/signin', data, {headers: LoginHeaders}
                        , {withCredentials: true}
                    )
                        .then(function (response) {
                            if (response.status === 200) {

                                let jwt = response.headers['authorization'];
                                inMemoryJWTService.setToken(jwt)
                                console.log('Authorization JWT available:', (jwt !== null && jwt !== undefined));

                                // ********************REST CALL TO login SERVICE***************
                                console.log("User logged in successfully.");

                                let includes = response.data.roles.includes(ADMIN_ROLE);
                                console.log("includes:", includes)
                                inMemoryJWTService.setAdmin(includes);

                                // Call the parent callback function
                                props.parentCallback(true);
                                navigate("/menu");
                            } else {
                                console.log("am in danger block")
                                setErrorMessages({name: "pass", message: errors.pass});
                                setErrorMessages({name: "uname", message: errors.uname});
                            }

                        }).catch(function (error) {
                        console.log(error);
                        setErrorMessages({name: "title", message: error.message});
                    });
                    // ************************ END OF CALL ***********
                });
            }
        );
    };

// Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

// JSX code for login form
    const renderForm = (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-10">
                            <div className="card">
                                {renderErrorMessage("title")}

                                <div className="row g-0">
                                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                                        <img src={yadagiriLogo} className="App-logo-nospin" alt="logo"/>
                                    </div>
                                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black">

                                            {/*Login Navigation Pane - START*/}

                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">

                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="user-pass-tab"
                                                            data-bs-toggle="pill" data-bs-target="#userPass"
                                                            type="button" role="tab" aria-controls="pills-profile"
                                                            aria-selected="false">Email
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">

                                                {/*Email and Password*/}
                                                <div id="userPass" className="tab-pane fade show active"
                                                     role="tabpanel" aria-labelledby="pills-home-tab">
                                                    <div className="form-outline mb-4">
                                                        <label className="form-label" htmlFor="uname">Email</label>
                                                        <input type="text" id="uname" name="uname"
                                                               className="form-control form-control-lg"/>
                                                        {renderErrorMessage("uname")}
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <label className="form-label" htmlFor="pass">Password</label>
                                                        <input type="password" id="pass" name="pass"
                                                               className="form-control form-control-lg"/>
                                                        {renderErrorMessage("pass")}
                                                    </div>
                                                </div>


                                            </div>

                                            {/*Login Navigation Pane - END*/}

                                            <div className="pt-1 mb-4">
                                                <div className="button-container">
                                                    <input type="submit" id="loginButton"/>
                                                </div>
                                            </div>

                                            <a className="small text-muted" href="#!">Forgot password?</a>
                                            <p className="mb-5 pb-lg-2">Don't have an account?
                                                <NavLink to="/registerUser">Register here</NavLink>
                                            </p>

                                            <a href="#!" className="small text-muted">Terms of use.</a>
                                            <a href="#!" className="small text-muted">Privacy policy</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );

    return (
        <div className="App">
            <div className="login-form">
                <div className="title">Sign Into GiRiFy</div>
                {renderForm}
            </div>
        </div>
    );
}

export default AdminLogin;