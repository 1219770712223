import React from "react";

import "../base/styles.css";
import $ from 'jquery';
import {useNavigate} from "react-router-dom";
import LogoutButton from "../login/Logout";
import inMemoryJWTService from "../base/InMemoryJWTService";
import Footer from "../base/Footer";


function UserMenu() {
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();

        // const userData = database.find((user) => user.username === uname.value);
        $("#chittiList").click(function () {
            console.log("chittiList")
            navigate("/chittiList");
        });

        $("#createChitti").click(function () {
            console.log("createChitti")
            navigate("/createChitti");
        });

    }

    const renderForm = (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="col-sm-12">
                    <fieldset style={{color: "blue", fontWeight: "500", alignContent: "center"}}>

                        <div className="form-outline mb-3 row"/>

                        <button type="submit" className="btn btn-primary" id="createChitti">Create New Chitti
                        </button>
                        <div className="form-outline mb-3 row"/>
                        <button type="submit" className="btn btn-primary" id="chittiList">Existing Chits
                        </button>

                    </fieldset>
                </div>
            </form>
        </div>
    );

    const renderIsAdmin = (
        // Find user login info
        $(document).ready(function () {
                console.log("props.isAdmin", inMemoryJWTService.getIsAdmin());
                if (!inMemoryJWTService.getIsAdmin()) {
                    $('#createChitti').hide();
                }
            }
        )
    );

    return (
        <div className="App">
            <LogoutButton/>
            <div className="title" style={{color: "CaptionText", fontWeight: "500"}}>Chitti Details</div>
            {renderForm}
            <Footer />
        </div>
    );
};
export default UserMenu;