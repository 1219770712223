// Home.js
import React from 'react';
import logo from '../base/moneyPlant-modified.png';
import browserLogo from '../base/timeCurrency-modified.png';

const Home = () => {
    return <div className="App">
        <header className="App-header">
            {navigator.userAgentData.mobile ? (
                <img src={logo} className="App-logo" alt="logo"/>
            ) : (
                <img src={browserLogo} className="App-logo" alt="browserLogo"/>
            )}

            <a
                className="App-link"
                href="/Login"
                target="_self"
                rel="noopener noreferrer"
            >
                Welcome to GiRiFy World
            </a>
        </header>
    </div>;
};

export default Home;
