import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";

import "../base/styles.css";
import yadagiriLogo from "../base/yadagiri-logo.png";
import $ from 'jquery';
import axios from "axios";
import {ADMIN_ROLE} from "../base/AppConstants";
import inMemoryJWTService from "../base/InMemoryJWTService";
import LoginHeaders from "./LoginHeaders"
import Footer from "../base/Footer";

function Login(props) {

    const navigate = useNavigate();
    // React States
    const [errorMessages, setErrorMessages] = useState({});

    const errors = {
        uname: "invalid username",
        title: "invalid login details",
        pass: "invalid password",
        mobile: "invalid mobile number",
        otp: "invalid otp",
        mId: "invalid memberId",
        mpass: "invalid password"
    };

    const extractSignInPath = (isAdminChecked) => {
        if (isAdminChecked) {
            return 'admin/signin';
        }
        return 'signin';
    }

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();

        // let mobile = $("#mobile").val(); TODO will implement this later
        // let otp = $("#otp").val();

        let uname = $("#uname").val();
        let pass = $("#pass").val();
        let isAdminChecked = $('#flexSwitchCheckDefault').prop("checked");
        console.log("isAdminChecked ::" + isAdminChecked);

        // let mId = $("#mId").val(); TODO will implement this later
        // let mPass = $("#mPass").val();
        let data = {
            // mobile: mobile, TODO will implement this later
            // otp: otp,
            username: uname,
            password: pass
            // member: mId,  TODO will implement this later
            // mPassword: mPass
        };
        console.log(data)
        console.log(process.env.REACT_APP_SECURITY_HOST)
        // ********************REST CALL TO SECURITY JWT SERVICE***************
        const signInRequestPath = process.env.REACT_APP_SECURITY_HOST + '/api/auth/' + extractSignInPath(isAdminChecked);
        console.log(signInRequestPath)

        axios.post(signInRequestPath, data, {headers: LoginHeaders}
            , {withCredentials: true}
        )
            .then(function (response) {
                if (response.status === 200) {

                    let jwt = response.headers['authorization'];
                    inMemoryJWTService.setToken(jwt)
                    console.log('Authorization JWT available:', (jwt !== null && jwt !== undefined));

                    // ********************REST CALL TO login SERVICE***************
                    console.log("User logged in successfully.");

                    let includes = response.data.roles.includes(ADMIN_ROLE);
                    console.log("includes:", includes)
                    inMemoryJWTService.setAdmin(includes);

                    if (isAdminChecked && includes) {
                        console.log("Admin logged in.")
                        // Call the parent callback function
                        props.parentCallback(true);
                        navigate("/menu");
                    } else {
                        console.log("User logged in.")
                        navigate('/chittiList');
                    }
                } else if (response.status === 401) {
                    setErrorMessages({name: "title", message: response.data.message});
                } else if (response.status === 403) {
                    setErrorMessages({name: "title", message: response.data});
                } else if (response.status === 400) {
                    setErrorMessages({name: "pass", message: errors.pass});
                    setErrorMessages({name: "uname", message: errors.uname});
                }

            }).catch(function (error) {
            console.log(error);
            var msg = error.message
            if (error.code === 'ERR_NETWORK') {
                msg = 'Girify Backend Services are Unavailable. Please try again later.'
            } else if (error.response.status === 403) {
                msg = error.response.data
            } else {
                msg = error.response.data.message;
            }
            setErrorMessages({name: "title", message: msg});
        });
        // ************************ END OF CALL ***********
    };

// Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

// JSX code for login form
    const renderForm = (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-10">
                            <div className="card">
                                {renderErrorMessage("title")}

                                <div className="row g-0">
                                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                                        <img src={yadagiriLogo} className="App-logo-nospin" alt="logo"/>
                                    </div>
                                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black">

                                            {/*<div className="d-flex align-items-center mb-3 pb-1">
                                                <i className="fas fa-cubes fa-2x me-3"></i>
                                                <span className="h1 fw-bold mb-0">GIRIFY</span>
                                            </div>

                                            <h5 className="fw-normal mb-3 pb-3" style={{alignSelf: "left"}}>Sign into your account</h5>*/}

                                            {/*Login Navigation Pane - START*/}

                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">

                                                {/*<li className="nav-item" role="presentation">*/}
                                                {/*    <button className="nav-link active" id="mobile-otp-tab"*/}
                                                {/*            data-bs-toggle="pill" data-bs-target="#mobileOtp"*/}
                                                {/*            type="button" role="tab" aria-controls="pills-home"*/}
                                                {/*            aria-selected="true">Mobile*/}
                                                {/*    </button>*/}
                                                {/*</li>*/}
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="user-pass-tab"
                                                            data-bs-toggle="pill" data-bs-target="#userPass"
                                                            type="button" role="tab" aria-controls="pills-profile"
                                                            aria-selected="false">Email
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    {/*<button className="nav-link" id="memberId-tab"*/}
                                                    {/*        data-bs-toggle="pill"*/}
                                                    {/*        data-bs-target="#memberPass"*/}
                                                    {/*        type="button" role="tab" aria-controls="pills-contact"*/}
                                                    {/*        aria-selected="false">MemberID*/}
                                                    {/*</button>*/}
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">

                                                {/*TODO Mobile and otp*/}
                                                <div id="mobileOtp" className="tab-pane fade "
                                                     role="tabpanel" aria-labelledby="pills-home-tab">
                                                    <div className="form-outline mb-4">
                                                        <label className="form-label" htmlFor="mobile">Mobile
                                                            Number</label>
                                                        <input type="text" id="mobile" name="mobile"
                                                               className="form-control form-control-lg"/>
                                                        {renderErrorMessage("mobile")}
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <label className="form-label" htmlFor="otp">OTP</label>
                                                        <input type="password" id="otp" name="otp"
                                                               className="form-control form-control-lg"/>
                                                        {renderErrorMessage("otp")}
                                                    </div>
                                                </div>

                                                {/*Email and Password*/}
                                                <div id="userPass" className="tab-pane fade show active"
                                                     role="tabpanel" aria-labelledby="pills-home-tab">
                                                    <div className="form-outline mb-4">
                                                        <label className="form-label" htmlFor="uname">Email</label>
                                                        <input type="text" id="uname" name="uname"
                                                               className="form-control form-control-lg"/>
                                                        {renderErrorMessage("uname")}
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <label className="form-label" htmlFor="pass">Password</label>
                                                        <input type="password" id="pass" name="pass"
                                                               className="form-control form-control-lg"/>
                                                        {renderErrorMessage("pass")}
                                                    </div>
                                                </div>

                                                {/*TODO MemberId and Password*/}
                                                <div id="memberPass" className="tab-pane fade"
                                                     role="tabpanel" aria-labelledby="pills-home-tab">
                                                    <div className="form-outline mb-4">
                                                        <label className="form-label" htmlFor="mId">Member Id</label>
                                                        <input type="text" id="mId" name="mId"
                                                               className="form-control form-control-lg"/>
                                                        {renderErrorMessage("mId")}
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <label className="form-label" htmlFor="mPass">Password</label>
                                                        <input type="password" id="mPass" name="mPass"
                                                               className="form-control form-control-lg"/>
                                                        {renderErrorMessage("mPass")}
                                                    </div>
                                                </div>
                                            </div>

                                            {/*Login Navigation Pane - END*/}
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch"
                                                       id="flexSwitchCheckDefault"/>
                                                <label className="form-check-label"
                                                       htmlFor="flexSwitchCheckChecked"> Are you an Admin ?
                                                </label>
                                            </div>

                                            <div className="pt-1 mb-4">
                                                <div className="button-container">
                                                    <input type="submit" id="loginButton"/>
                                                </div>
                                            </div>

                                            <a className="small text-muted" href="#!">Forgot password?</a>
                                            <p className="mb-5 pb-lg-2">Don't have an account?
                                                <NavLink to="/registerUser">OnBoard As a User / Owner</NavLink>
                                            </p>

                                            <a href="#!" className="small text-muted">Terms of use.</a>
                                            <a href="#!" className="small text-muted">Privacy policy</a>
                                            <p className="small text-muted">{process.env.REACT_APP_VERSION} </p>
                                            <p className="small text-muted">{'Release Date : '}{new Date().toLocaleDateString()}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );

    return (
        <div className="App">
            <div className="login-form">
                <div className="title">Sign Into GiRiFy</div>
                {renderForm}
            </div>
            <Footer />
        </div>
    );
}

export default Login;
