import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import "../base/editChittiUserModal.css";
import LogoutButton from "../login/Logout";
import axios from "axios";
import inMemoryJWTService from "../base/InMemoryJWTService";
import chittiDetailsInfo from "./ChittiDetailsInfoTab";
import $ from 'jquery';
import Footer from "../base/Footer";


function ChittiDetails() {
    const [statusMessage, setStatusMessage] = useState("");
    const [chit, setChit] = useState({});
    const [associatedChittiUsers, setAssociatedChittiUsers] = useState([]);
    const [searchUsers, setSearchUsers] = useState([]);
    const [activeTab, setActiveTab] = useState("home");

    const {chittiCode} = useParams();

    useEffect(() => {
        loadChittiDetails();
    }, []);

    const renderStatusAndMessage = () => {
        statusMessage !== "" &&
        (<div className="col-lg-12"> {statusMessage} </div>)
    }

    function loadChittiDetails() {
        // ********************REST CALL TO SERVICE***************
        const url = process.env.REACT_APP_BACKEND_HOST;
        console.log("url :" + url);
        console.log("url with code:" + '/girify/chits/' + chittiCode);

        axios.get(url + '/girify/chits/' + chittiCode, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                "Access-Control-Max-Age": "3600",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "Content-Type, Authorization, authorization, set_cookie",
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": inMemoryJWTService.getToken()
            }
        }, {withCredentials: true})
            .then(function (response) {

                if (response.status === 200) {
                    let data = response.data;
                    if (data !== undefined && data != null) {
                        console.log("Retrieving Chitti Details are successful :" + response.status);
                        setChit(data.chittiEnrichedProfile);
                    }
                } else {
                    console.log("Error in retreiving Chitti Details")
                    setStatusMessage(response.data.errorMessage);
                }

            })
            .catch(function (error) {
                console.log(error);
                setStatusMessage(error);
            });
        // ***********************************
    }

    const getChittiUserMappings = () => {
        // ********************REST CALL TO SERVICE***************
        const url = process.env.REACT_APP_BACKEND_HOST;
        console.log("url with code:" + '/girify/chits/users/' + chittiCode);

        axios.get(url + '/girify/chits/users/' + chittiCode, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                "Access-Control-Max-Age": "3600",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "Content-Type, Authorization, authorization, set_cookie",
                "Accept": "application/json",
                "Content-Type": "application/hal+json",
                "Authorization": inMemoryJWTService.getToken()
            }
        }, {withCredentials: true})
            .then(function (response) {

                if (response.status === 200) {
                    let data = response.data;
                    if (data !== undefined && data != null) {
                        console.log("Retreiving chitti-user mappings are successful. :" + response.status);
                        setAssociatedChittiUsers(data.chittiUsers);
                    }
                } else {
                    console.log("Error in retreiving Chitti Users Mappings Details")
                    setStatusMessage(response.data.errorMessage);
                }

            })
            .catch(function (error) {
                console.log(error);
                setStatusMessage(error);
            });
        // ***********************************
    }

    const findUsers = () => {
        // ********************REST CALL TO SERVICE***************
        const url = process.env.REACT_APP_BACKEND_HOST;
        console.log("url :" + url);

        var filter = document.getElementById('myInput').value;
        console.log("filter :", filter)

        if (filter !== '') {
            axios.get(url + '/girify/users?username=' + filter, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                    "Access-Control-Max-Age": "3600",
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Allow-Headers": "Content-Type, Authorization, authorization, set_cookie",
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": inMemoryJWTService.getToken()
                }
            }, {withCredentials: true})
                .then(function (response) {

                    if (response.status === 200) {
                        let data = response.data;
                        if (data !== undefined && data != null) {
                            console.log(data)
                            setSearchUsers(data);
                        }
                    } else {
                        console.log("am in danger block")
                        setStatusMessage(response.data.errorMessage);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    setStatusMessage(error);
                });
        } else {
            setSearchUsers([])
        }
        // ***********************************
    }


    const chittiStatementsTab = () => (
        <div id="chittiUsersId" className="table-responsive fixed">
            {/*<table className="table col-md-12 table-striped table-hover" id={"chittiTable"}>*/}
            {/*    <thead className="thead-dark">*/}
            {/*    <tr id="trHeaders">*/}
            {/*        <th>User</th>*/}
            {/*        <th>Monthly Amout</th>*/}
            {/*        <th>Previous Due</th>*/}
            {/*        <th>Total</th>*/}
            {/*        <th>Paid Amount For This Month</th>*/}
            {/*        <th>Status</th>*/}
            {/*        <th>Comments</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody id={"tableBody"}>*/}
            {/*    /!*{this.state.associatedChittiUsers.map(item => (*!/*/}
            {/*    /!*    <tr id={"tableRow"}>*!/*/}
            {/*    /!*    <td>{item.id}</td>*!/*/}
            {/*    /!*    <td id="chittiName"><Link*!/*/}
            {/*    /!*        to={`/chittiList/${item.chittiCode}`}>{item.chittiName}</Link></td>*!/*/}
            {/*    /!*    <td id="chittiCode"><Link*!/*/}
            {/*    /!*        to={`/chittiList/${item.chittiCode}`}>{item.chittiCode}</Link></td>*!/*/}
            {/*    /!*    <td>{item.chittiOwner}</td>*!/*/}
            {/*    /!*    <td id="status">{item.status}</td>*!/*/}
            {/*    /!*    <td>{item.startDate}</td>*!/*/}
            {/*    /!*    <td>{item.endDate}</td>*!/*/}
            {/*    /!*    <td>{item.totalMonths}</td>*!/*/}
            {/*    /!*    <td>{item.totalMembers}</td>*!/*/}
            {/*    /!*    <td>{item.interest}</td>*!/*/}
            {/*    /!*    <td>{item.adminCommission}</td>*!/*/}
            {/*    /!*    <td>{item.chittiAmount}</td>*!/*/}
            {/*    /!*    <td>{item.monthlyPremium}</td>*!/*/}

            {/*    /!*</tr>))}*!/*/}
            {/*    </tbody>*/}
            {/*</table>*/}
        </div>
    );

    const addUserToChitti = () => {

        $('#addCloseButton').click();
        // ********************REST CALL TO SERVICE***************

        const url = process.env.REACT_APP_BACKEND_HOST;
        var selectedUserIds = [];
        $('#searchUsersList').children().map((i, v) => selectedUserIds.push(v.id))
        console.log("selectedUserIds :", selectedUserIds);
        const requestData = {
            selectedUserIds: selectedUserIds,
            chittiCode: chittiCode
        };

        const securityHeaders = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
            "Access-Control-Max-Age": "3600",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Headers": "Content-Type, authorization, set_cookie",
            "Accept": "application/json",
            "Authorization": inMemoryJWTService.getToken(),
            "Content-Type": "application/json"
        };

        axios.put(url + '/girify/chits/users', requestData, {headers: securityHeaders},
            {withCredentials: true})
            .then(function (response) {
                if (response.status === 201) {
                    let data = response.data;
                    if (data !== undefined && data != null) {
                        console.log("am in success block :" + response.status);
                        setAssociatedChittiUsers(data.chittiUsers);
                        console.log(data.chittiUsers)
                        setStatusMessage("User linked with Chitti successfully.")
                        setSearchUsers([])
                    }
                } else {
                    console.log("am in danger block")
                    setStatusMessage(response.data.errorMessage);
                }
            })
            .catch(function (error) {
                console.log(error);
                setStatusMessage(error);
            });
        // ***********************************
    }

    const updateChittiUserStatus = () => {

        // ********************REST CALL TO SERVICE***************
        let id = $('#editID').val();
        let memberId = $('#editMember').val();
        let isChecked = $('#editStatus').prop("checked");
        const url = process.env.REACT_APP_BACKEND_HOST;
        console.log("selectedUserId :", memberId);
        let status = 'INACTIVE'

        if (isChecked) {
            status = 'ACTIVE'
        }

        console.log("STATUS :", status);

        axios.put(url + '/girify/chits/' + chittiCode + '/' + memberId + '/' + status, null, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                "Access-Control-Max-Age": "3600",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "Content-Type, Authorization, authorization, set_cookie",
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": inMemoryJWTService.getToken()
            }
        }, {withCredentials: true})
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data;
                    if (data !== undefined && data != null) {
                        console.log("am in success block :" + response.status);
                        setAssociatedChittiUsers(data.chittiUsers);
                        console.log(data.chittiUsers)
                        $("#editCloseButton").click();
                        getChittiUserMappings()
                    }
                } else {
                    console.log("am in danger block")
                    setStatusMessage(response.data.errorMessage);
                }
            })
            .catch(function (error) {
                console.log(error);
                setStatusMessage(error);
            });
        // ***********************************
    }

    const addUsersToStaging = (eve) => {
        var name = eve.target.innerText;
        var memberId = eve.target.id;
        var fixingContent = '<li className="searchUsersLi" id=' + memberId + '>' + name + '<input type="button" onclick="$(this).closest(\'#searchUsersList li\').remove()" value="X"></input></li>';
        document.getElementById("searchUsersList").innerHTML += fixingContent
        document.getElementById("myInput").append('')
        setSearchUsers([])
    }


    const userDetail = (ele) => {
        console.log("ele on clicked", ele)

        //the <tr> variable is use to set the parentNode from "ele
        var tr = ele.target.parentNode.parentNode.parentNode;
        console.log("tr", tr)
        //I get the value from the cells (td) using the parentNode (var tr)

        var id = tr.cells[0].textContent;
        var memeberName = tr.cells[1].textContent;
        var memberId = tr.cells[1].id;
        var status = tr.cells[2].childNodes[0];

        //Prefill the fields with the gathered information
        var myModal = $('#editChittiUserModal');

        $('h4.modal-title', myModal).html('Edit Chitti User Status: ' + memberId);
        $('#editID', myModal).val(id);
        $('#editMemberName', myModal).val(memeberName);
        $('#editMember', myModal).val(memberId);
        $('#editStatus').prop("checked", status.checked);
    }

    const chittiMembersTab = () => (

        <div className="col-lg-12 table-responsive fixed">
            <a href="#!" className="edit" data-toggle="modal"
               data-target="#addChittiUsersModal" className="table-responsive"
               id="addChittiUsersHrefId">Add Members To Chitti</a>
            <div className="row table-responsive">
                <table className="table container-fluid table-hover" id={"chittiTable"}>
                    <thead className="thead-dark">
                    <tr id="trHeaders">
                        <th>Id</th>
                        <th>User</th>
                        <th>Active / Inactive</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody id={"tableBody"}>
                    {
                        associatedChittiUsers.map(item => (
                            <tr id={"tableRow"}>
                                <td>{item.id}</td>
                                <td id={item.member}>
                                    <Link to={`/usersList`}>{item.memberName}</Link></td>
                                <td id="Status">
                                    <input disabled className="form-check-input"
                                           type="checkbox"
                                           id={item.id}
                                           name={item.id}
                                           checked={item.status === 'ACTIVE'}
                                           aria-label="Status"
                                    />
                                </td>
                                <td>
                                    <a href="#!" className="edit" data-toggle="modal"
                                       data-target="#editChittiUserModal" onClick={userDetail}
                                       id="editModalHrefId"><i
                                        className="material-icons" data-toggle="tooltip"
                                        title="Edit">&#xE254;</i></a>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>

            <div id="editChittiUserModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Chit User Status</h4>
                            <br/>
                            <button type="button" className="close" data-dismiss="modal" id="editCloseButton"
                                    aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form id="updaterChitForm">
                                <div className="form-group">
                                    <label>Id</label>
                                    <input type="text" className="form-control custom-control custom-checkbox"
                                           id="editID" required readOnly={true}/>
                                </div>

                                <div className="form-group">
                                    <label>MemberName</label>
                                    <input type="text" className="form-control" id="editMemberName" required
                                           readOnly={true}/>
                                </div>

                                <div className="form-group">
                                    <label>MemberId</label>
                                    <input type="text" className="form-control" id="editMember" required
                                           readOnly={true}/>
                                </div>

                                <div className="form-group custom-control">
                                    <input type="checkbox" className="form-control custom-control-input"
                                           id="editStatus"/>
                                    <label className="custom-control-label"
                                           htmlFor="editStatus">ACTIVE/INACTIVE ?</label>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <input type="button" className="btn btn-default" data-dismiss="modal"
                                   value="Cancel"/>
                            <input type="button" className="btn btn-info" value="Save" id="updateUser"
                                   onClick={updateChittiUserStatus}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div id="chittiUsersId" className="table-responsive fixed">
            </div>

            {/*Add Users to Chitti Dialog Modal -Start*/}

            <div id="addChittiUsersModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{color: "CaptionText"}}>Add Users To Chitti</h4>
                            <button type="button" className="close" data-dismiss="modal" id="addCloseButton"
                                    aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form id="SearchUsersForm">

                                <div className="form-group">
                                    <div className="form-group">
                                        <label>Search User With Username</label>
                                        <input type="text" className="form-control" id="myInput"
                                               placeholder="Search for names or status.." onKeyUp={findUsers}/>
                                        {console.log(searchUsers)}

                                        <ol className="List" id="userResultsList" style={{color: "CaptionText"}}>
                                            {searchUsers.map(item => (
                                                <div id={item.memberId} onClick={addUsersToStaging}>{item.name}
                                                </div>
                                            ))}
                                        </ol>
                                        <div style={{color: "CaptionText"}}>
                                            <ol className="searchUsersList" id="searchUsersList">
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <input type="button" className="btn btn-default" data-dismiss="modal"
                                   value="Cancel"/>
                            <input type="button" className="btn btn-info" value="Save" id="addUsersToChittiBtn"
                                   onClick={addUserToChitti}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/*Add Users to Chitti Dialog Modal -End*/}
        </div>
    );

    // Functions to handle Tab Switching
    const handleHome = () => {
        setActiveTab("home");
    };
    const handleStatement = () => {
        setActiveTab("statement");
    };

    const handleMembers = () => {
        setActiveTab("members");
        getChittiUserMappings();
        chittiMembersTab();
    };

    return (
        <div>
            <LogoutButton/>
            <div className="Tabs">

                {renderStatusAndMessage}
                <div className="nav">
                    <div className="col-lg-12">

                        <a href="#"
                           className={activeTab === "home" ? "active" : ""}
                           onClick={handleHome}>
                            Chitti Details
                        </a>

                        <br/>

                        <a href="#"
                           className={activeTab === "statement" ? "active" : ""}
                           onClick={handleStatement}>
                            Statement
                        </a>

                        <br/>

                        {inMemoryJWTService.getIsAdmin()
                            && <a href="#" className={activeTab === "members" ? "active" : ""} onClick={handleMembers}>
                                Chitti Members
                            </a>}

                    </div>
                </div>

                <div className="outlet">
                    {activeTab === "home" && chittiDetailsInfo(chit)}
                    {inMemoryJWTService.getIsAdmin() && activeTab === "members" && chittiMembersTab()}
                    {activeTab === "statement" && chittiStatementsTab()}
                </div>

            </div>
            <Footer/>
        </div>
    );
}

export default ChittiDetails;
