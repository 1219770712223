import React from "react";

import "../base/styles.css";
import $ from 'jquery';
import {useNavigate} from "react-router-dom";
import LogoutButton from "./Logout";
import Footer from "../base/Footer";


function Menu() {
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();

        // Find user login info

        // const userData = database.find((user) => user.username === uname.value);
        $("#usersButton").click(function () {
            console.log("userMenu")
            navigate("/usersList");
        });

        $("#chittiDetailsButton").click(function () {
            console.log("chittiList")
            navigate("/chittiList");
        });

    }

    const renderForm = (
        <div>
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="col-sm-12">
                    <fieldset style={{color: "blue", fontWeight: "500", alignContent: "center"}}>

                        <div className="form-outline mb-3 row"/>

                        <button type="submit" className="btn btn-primary" id="usersButton">Users
                        </button>
                        <div className="form-outline mb-3 row"/>
                        <button type="submit" className="btn btn-primary" id="chittiDetailsButton">Chitti Details
                        </button>

                    </fieldset>
                </div>
            </form>
        </div>
            <Footer/>
        </div>
    );

    return (
        <div className="App">
            <LogoutButton/>
            <div className="title" style={{color: "CaptionText", fontWeight: "500"}}>Menu</div>
            {renderForm}
        </div>
    );
};
export default Menu;